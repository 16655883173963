import React, { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { v4 as uuidv4 } from 'uuid';
// require('dotenv').config();
import { useNavigate, useLocation } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useEnglish from '../languages/useEnglish';
import { encryptData, decryptData } from '../utils/cryptoUtils';
import "./QR.css";
let currentIndex = 0;
let uniqueKey = '';
const Feedback = () => {
  const [isValid, setIsValid] = useState(true);
  const langs = useLanguage();
  const eng = useEnglish();
  const engFeedback = eng.feedback;
  const lang = langs.feedback;
  const choosenLang = useSelector((state) => state.language.language);

  const idsToCheck = [6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120, 126, 132, 138, 144, 150, 156, 162, 168, 174, 180];
  const [rating, setRating] = useState('');
  const [reason, setReason] = useState([]);
  const [isButtonPressed, setisButtonPressed] = useState(false);
  const [comments, setComments] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [error, setError] = useState('');
  const [questionData, setQuestionData] = useState('');
  const [questionId, setQuestionId] = useState(1);
  const [feedbackReasons, setFeedbackReasons] = useState([]);
  const [ratingKey, setRatingKey] = useState("");
  const [feedbackCateg, setFeedbackCateg] = useState("");

  // const [fbType, setFbtype] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  let feedbackType = location.state?.feedbackType;
  const [feedType, setFeedType] = useState(feedbackType);
  if (localStorage.getItem("kiosk_or_qr") === "kiosk") {
    feedbackType = "service";
  }
  const transactionTypes = location.state?.transactionTypes?.uniqueTransactionTypes ?? [];
  const [fed, setFed] = useState(transactionTypes?.reduce((acc, transaction) => {
    acc[transaction.unique_key] = { rating: null, reasons: [], comment: '', ratingKey: null, transactionType: null };
    return acc;
  }, {}));
  console.log(transactionTypes, 'transaction types');
  // useEffect(() => {
  //   loadCaptchaEnginge(4); 
  // }, []);
  useEffect(() => {
    console.log("this is the feeedback type this one", feedbackType);
    //loadCaptchaEnginge(4);
    if (transactionTypes.length > 0) {
      console.log("this is the inside feebacktype", feedbackType);
      if (feedType === "employee" && transactionTypes.length === 0) {
        loadCaptchaEnginge(4);
      }

      loadCaptchaEnginge(4);
    } else if (feedType === "employee") {
      console.log("this is the outer type", feedbackType);
      loadCaptchaEnginge(4);
    }
  }, [feedType]);

  useEffect(() => {
    if (transactionTypes === null || transactionTypes.length === 0) {
      {
        const searchParams = new URLSearchParams(window.location.search);
        const uniqueKey = searchParams.get('id');
       
        if (feedbackType === "service") {
          if(!uniqueKey)
            {
              setTimeout(() => {
                navigate("/cankiosk");
              }, 3000);
            }
          
        }
      }
    }
  }, [transactionTypes]);

  const ratingOptions = [
    { label: "1", key: lang.belowAverage, className: "fig1", ratingKey: 'belowAverage' },
    { label: "2", key: lang.average, className: "fig2", ratingKey: 'average' },
    { label: "3", key: lang.good, className: "fig3", ratingKey: 'good' },
    { label: "4", key: lang.veryGood, className: "fig4", ratingKey: 'veryGood' },
    { label: "5", key: lang.excellent, className: "fig5", ratingKey: 'excellent' }
  ];
  const poorRatingOptions = [
    lang.belowAverage,
    lang.average,
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uniqueKey = searchParams.get('id');
    
    // console.log('uniqueKey', uniqueKey, feedbackType)
    if (uniqueKey) {
     // loadCaptchaEnginge(4);
      feedbackType = "service";
      setFeedType('employee');

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkTimestamp`, { unique_key: uniqueKey })
        .then(response => {
          console.log('checkTimestamp', response.data)
          if (response.data.valid === true) {
            setIsValid(true);
          } else {
            setIsValid(false);
            setError("Invalid Link")
            toast.error('The link is expired or invalid.');
          }
        })
        .catch(error => {
          console.error('API Error:', error);
          setIsValid(false);
          setError('An error occurred while validating the link.');
        });
    }
  }, []);

  useEffect(() => {
    const fetchResponses = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/getReasons`, {
          language: choosenLang,
          service: searchParams.get('id') ? 'service' : feedbackType || 'general'
        });
        console.log('Responses:', response.data);
        setFeedbackReasons(response.data?.responses || [])
      } catch (error) {
        setFeedbackReasons([])
        console.error('Error fetching responses:', error);
        setError('Failed to load responses. Please try again.');
      }
    };

    fetchResponses();
  }, [choosenLang, feedbackType]);


  useEffect(() => {
    const fetchQuestions = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/getQuestions`, {
          type: searchParams.get('id') ? 'service' : feedbackType || 'general',
          language: choosenLang,
        });
        console.log(response, 'responses');
        setQuestionId(response.data.questions[0].id)
        setQuestionData(response.data.questions[0].value);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError('Failed to load questions. Please try again.');
      }
    };

    fetchQuestions();
  }, [choosenLang, feedbackType])

  useEffect(() => {
    console.log(reason);
  }, [reason])

  const handleRatingChange = (selectedRating, ratingKey, transaction) => {
    currentIndex = transaction.unique_key;
    uniqueKey = transaction.unique_key;
    // setRating(selectedRating);
    setRating((prevRatings) => {
      const updatedRatings = [...prevRatings];
      updatedRatings[transaction.unique_key] = selectedRating;
      return updatedRatings;
    });


    setFed((prev) => ({
      ...prev,
      [transaction.unique_key]: {
        ...prev[transaction.unique_key],
        rating: selectedRating,
        // reasons:poorRatingOptions.includes(selectedRating) ? prev[transaction.unique_key].reasons : [],
        ratingKey: ratingKey,
        transactionType: transaction.transaction_type
      }
    }));

    // setFeedbackCateg((prevRatings)=>{
    //   const updatedRatings = [...prevRatings];
    //   updatedRatings[currentIndex] = {rating:selectedRating,questionId:null};
    //   console.log("update rating",updatedRatings);
    //   return updatedRatings;
    // })
    setRatingKey(ratingKey);

    //setReason([]); 
    // setComments(''); 
    setError('');

  };
  const handleCommentChange = (e, uniqueKey) => {
    setFed((prev) => ({
      ...prev,
      [uniqueKey]: {
        ...prev[uniqueKey],
        comment: e.target.value
      }
    }));
  };

  const handleReasonChange = (e) => {
    const { id, checked } = e.target;
    const idNumber = Number(id); // Ensure the id is handled as a number

    setReason((prevReasons) => {
      const newReasons = checked
        ? [...new Set([...prevReasons, idNumber])] // Use idNumber instead of id
        : prevReasons.filter((reason) => reason !== idNumber);

      console.log("Updated reasons:", newReasons);
      return newReasons;
    });
    // const { id, checked } = e.target;
    // console.log("ids",id);
    // setFed((prev)=>{

    //   return {
    //     ...prev,
    //     [uniqueKey]: {
    //       ...prev[uniqueKey],
    //       reasons: checked
    //         ? [...prev[uniqueKey].reasons, parseInt(id)]
    //         : prev[uniqueKey]?.reasons.filter((reasonId) => reasonId !== parseInt(id))
    //     }
    //   };
    // })
    // setFed((prev) => ({
    //   ...prev,
    //   [uniqueKey]: {
    //     ...prev[uniqueKey],
    //     reasons: checked
    //       ? [...prev[uniqueKey].reasons, parseInt(id)]
    //       : prev[uniqueKey].reasons.filter((reasonId) => reasonId !== id)
    //   }
    // }));
  };



  const validateUniqueID = async (uniqueKey) => {
    const searchParams = new URLSearchParams(window.location.search);
    const encryptedEmpCode = localStorage.getItem('emp_code') || localStorage.getItem("employeeCode");
    console.log("encryptedEmpCode", encryptedEmpCode);
    const empCode = decryptData(encryptedEmpCode);
    console.log("decryptedEmpCode", empCode);
    try {
      let feedbackTypeLocal = location.state?.feedbackType || "general";
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-uniquekey`, {
        uniqueKey: uniqueKey,
        feedbackType: searchParams.get('id') ? 'service' : feedbackTypeLocal,
        empCode: empCode,
        service: 'feedback'
      });
      console.log("response from validation", response)
      const dpCode = response?.data?.dp_code || localStorage.getItem('dp_code') || localStorage.getItem("employeeDpCode");
      const custId = response?.data?.customer_id;
      const channel = response?.data?.channel;
      console.log("dpCode", dpCode);
      console.log("custId", custId);
      localStorage.setItem('customer_id', custId);
      localStorage.setItem('channel', channel);
      if (response?.data?.dp_code) {
        const encryptedDpCode = encryptData(dpCode);
        localStorage.setItem('dp_code', encryptedDpCode);
      }

      if (response.data.status === 'valid') {
        return true;
      } else if (response.data.status === 'invalid') {

        if (feedbackTypeLocal == 'employee') {
          localStorage.clear()
        } else {
          // localStorage.removeItem('branch_name');
          localStorage.removeItem('uniqueKey');
          localStorage.removeItem('phone_number');
          localStorage.removeItem('customer_id');
        }

        if (response.data.lastUpdated == 2) {
          toast.error(response.data.message);
          navigate(`/feedback-response`, { state: { fbType: 'err' } })
        } else if (response.data.lastUpdated < 1) {
          // alert(lang.error.twice);
          // navigate(`/feedback-response${location.search}`,{ state: { fbType: feedbackCateg } })
          navigate(`/feedback-response`, { state: { fbType: feedbackCateg } })
        }
        else {
          toast.error(lang.error.transaction)
        }
        return false;
      }
      else {
        if (feedbackTypeLocal == 'employee') {
          localStorage.clear()
        } else {
          // localStorage.removeItem('branch_name');
          localStorage.removeItem('uniqueKey');
          localStorage.removeItem('phone_number');
          localStorage.removeItem('customer_id');
        }

        navigate(`/feedback-response`, { state: { fbType: feedbackCateg } })
        return false;
      }
    } catch (error) {
      console.error('Error validating unique ID:', error);
      toast.error(lang.error.submitFeedback);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!rating) {
      setError('Please select a rating.');
      return;
    }

    // Validate reasons if rating is 'poor' or 'very poor'
    if ((rating === lang.average || rating === lang.belowAverage) && reason.length === 0) {
      setError('Please select at least one reason for your rating.');
      return;
    }

    // Validate comments if 'others' is selected
    if (reason.includes('others') && comments.trim() === '') {
      setError('Please provide details for the "Others" option.');
      return;
    }

    if (!validateCaptcha(captchaInput)) {
      setError(lang.error.incorrectCaptcha);
      loadCaptchaEnginge(4);
      setCaptcha('');
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    let uniqueKey = searchParams.get('id');

    if (!uniqueKey) {
      uniqueKey = localStorage.getItem('uniqueKey');
    }

    if (!uniqueKey) {
      toast.error(lang.error.submitFeedback);
      return;
    }

    const isUniqueIDValid = await validateUniqueID(uniqueKey);
    if (!isUniqueIDValid) {
      return;
    }
    const encryptedDpCode = localStorage.getItem('dp_code') || localStorage.getItem('employeeDpCode');
    console.log("encryptedDpCode", encryptedDpCode);
    const decryptedDpCode = decryptData(encryptedDpCode);
    console.log("decryptedDpCode", decryptedDpCode);

    const encryptedEmpCode = localStorage.getItem('emp_code') || localStorage.getItem("employeeCode");
    console.log("encryptedEmpCode", encryptedEmpCode);
    const decryptedEmpCode = decryptData(encryptedEmpCode);
    console.log("decryptedEmpCode", decryptedEmpCode);
    const customer_id = localStorage.getItem('customer_id') || "user1";

    const transactionType = localStorage.getItem('transactionType') || "";
    let feedback_type = location.state?.feedbackType || "general";
    const phone_number = localStorage.getItem('phone_number');
    const isKiosk = (localStorage.getItem('kiosk_or_qr') && (localStorage.getItem('kiosk_or_qr') == 'kiosk')) ? true : false;
    let channel = isKiosk ? 'kiosk' : localStorage.getItem('channel');
    let feedback_category;

    if (decryptedEmpCode) {
      feedback_type = 'employee';
    }
    if ((feedback_type === 'employee')) {
      channel = 'QR';
    }
    if ((transactionTypes?.length > 0) && (feedback_type === 'employee')) {
      channel = 'QR';
      feedback_type = 'service';
    }
  
    if(transactionTypes.length === 0)
    {
      try{
        let data = {
          unique_key:uniqueKey
        }
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/decryptUniqueKey`, data);
         console.log(response.data,'response.data');
         uniqueKey = response.data.unique_key;
      }
      catch(err){

      }
      
    }


    if (feedback_type === 'employee') {
      feedback_category = 'QR';
    } else if (feedback_type === 'service') {
      feedback_category = 'transaction';
    } else if (searchParams.get('id')) {
      feedback_category = 'transaction';
    } else {
      feedback_category = 'kiosk';
    }
    setFeedbackCateg(feedback_category);
    // setFbtype(feedback_category);

    if (!decryptedDpCode) {
      if (isKiosk) {
        navigate(`/cankiosk`);
      } else {
        navigate(`/phone-number`);
      }

    }
    const dataArray = Object.entries(fed).map(([id, details]) => ({
      id: id,
      answer: details.rating,
      free_text: (details.rating === lang.average || details.rating === lang.belowAverage) ? comments : '',
      other_opts: (details.rating === lang.average || details.rating === lang.belowAverage) ? reason : [],
      ratingKey: details.ratingKey,
      transaction_type: details.transactionType
    }));
    const updateRequest = dataArray.filter(item => item.answer !== null && item.answer !== "");
    console.log("updated request", updateRequest)
    const newFeedbackType = searchParams.get("id") ? "service" : feedback_type;
    const requestBody = {
      Request: {
        body: {
          encryptData: updateRequest.map((item) => ({
            ...item,
            questions_id: questionId,
            user_id: customer_id || "customer1",
            unique_id: transactionTypes.length > 0 ? item.id : uniqueKey,//decryptData(uniqueKey),
            answer_english: engFeedback[item.ratingKey],
            language: choosenLang,
            user_type: feedback_type === "employee" ? "employee" : "customer",
            //feedback_category,
            feedback_type: newFeedbackType,
            feedback_category: newFeedbackType === 'service' ? "transaction" : feedback_category,
            dp_code: decryptedDpCode,
            mobile_number: phone_number,
            notification_opted: "false",
            emp_id: decryptedEmpCode,
            channel: channel ?? null,
          })),
        },
      },
    };
    console.log(requestBody);
  
    setisButtonPressed(true);
    // const requestBody = {
    //   Request: {
    //     body: {
    //       encryptData: {
    //         questions_id: questionId,
    //         user_id: customer_id || "customer1",
    //         unique_id: uniqueKey,
    //         answer: rating,
    //         answer_english : engFeedback[ratingKey],
    //         language: choosenLang,
    //         user_type: feedback_type == 'employee' ? "employee" : "customer",
    //         feedback_category,
    //         feedback_type: searchParams.get('id') ? 'service' : feedback_type,
    //         dp_code: decryptedDpCode,
    //         transaction_type: transactionType || "general",
    //         mobile_number: phone_number,
    //         notification_opted: "false",
    //         free_text: (rating === lang.average || rating === lang.belowAverage) ? comments : '',
    //         other_opts : reason,
    //         emp_id : decryptedEmpCode,
    //         channel:channel??null
    //       }
    //     }
    //   }
    // };
    // console.log("request body",requestBody)
    // return false
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/saveTransactionFeedbackResponse`, requestBody);
      setisButtonPressed(false);
      if (feedback_type == 'employee') {
        localStorage.clear()
      } else {
        // localStorage.removeItem('branch_name');
        localStorage.removeItem('uniqueKey');
        localStorage.removeItem('phone_number');
        localStorage.removeItem('customer_id');
      }
      // navigate(`/thank-you${location.search}`, { state: { fbType: feedback_category } });
      navigate(`/thank-you`, { state: { fbType: feedback_category } });
    } catch (error) {
      setisButtonPressed(false);
      console.error('API call failed:', error);
      setError('Something went wrong. Please try again.');
    }
  };

  useEffect(() => {
    if(feedbackType != 'service')
    {
      const dpCodeVal = localStorage.getItem("employeeCode") || localStorage.getItem('dp_code');
      if (dpCodeVal == undefined || dpCodeVal == "") {
        setIsValid(false);
        setError("The link is expired or invalid.")
        // toast.error('The link is expired or invalid.');
      }
    }
    
  }, [])

  function renderComment(unique_key) {

    //const isRating = rating.some(value => value === lang.average || value === lang.belowAverage);


    if (fed[unique_key]?.rating === lang.belowAverage || fed[unique_key]?.rating === lang.average) {

      return (
        <div className="col-lg-12 badQue">
          <h4>{lang.poorFeedbackHeader}</h4>
          <form>
            {feedbackReasons.map((reasonItem) => {

              return (
                <div className="form-group" key={reasonItem.id}>
                  <input
                    type="checkbox"
                    id={reasonItem.id}
                    onChange={(e) => handleReasonChange(e, unique_key)}
                    checked={fed[uniqueKey]?.reasons?.includes(reasonItem.id)}
                  />
                  <label htmlFor={reasonItem.id}>
                    {reasonItem.value}
                  </label>
                </div>
              );
            })}
            {fed[unique_key]?.reasons?.includes("opt6") && (
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={comments}
                  rows={10}
                  cols={100}
                  onChange={(e) => setComments(e.target.value)}
                  maxLength="500"
                ></textarea>
              </div>
            )}
          </form>
        </div>
      )
    }


  }
  const isFeedbackRequired = Object.values(fed).some(
    (data) => data.rating === lang.belowAverage || data.rating === lang.average
  );

  function renderFeedback() {
   
    if (feedType === "service" && localStorage.getItem("kiosk_or_qr")) {
      if (transactionTypes && transactionTypes.length > 0) {
        return (
          <div>
            {transactionTypes.map((transaction, index) => (
              <div key={transaction.unique_key} className="transaction-card">
                <h3>
                  {transaction.transaction_type
                    ? index + 1 + ". " + transaction.transaction_type
                    : ""}
                </h3>

                <ul className="list-inline">
                  {ratingOptions.map((option) => (
                    <li
                      key={option.key}
                      className={`list-inline-item ${fed[transaction.unique_key]?.rating === option.key
                          ? "highlight"
                          : ""
                        }`}
                      onClick={() =>
                        handleRatingChange(
                          option.key,
                          option.ratingKey,
                          transaction
                        )
                      }
                    >
                      <figure
                        className={`${option.className} ${fed[transaction.unique_key]?.rating === option.key
                            ? "highlight"
                            : ""
                          }`}
                      >
                        {option.label}
                      </figure>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            {isFeedbackRequired && (
              <div className="col-lg-12 badQue">
                <h4>{lang.poorFeedbackHeader}</h4>
                <form>

                  {feedbackReasons.map((reasonItem) => (
                    <div className="form-group" key={reasonItem.id}>

                      <input
                        type="checkbox"
                        id={reasonItem.id}
                        onChange={(e) => handleReasonChange(e)}
                        checked={reason.includes(reasonItem.id)}
                      />
                      <label htmlFor={reasonItem.id}>{reasonItem.value}</label>
                    </div>
                  ))}


                  {idsToCheck.some((id) => reason.includes(id)) && (
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        value={comments}
                        rows={3}
                        cols={50}
                        onChange={(e) => setComments(e.target.value)}
                        maxLength="500"
                        placeholder="Additional comments..."
                      ></textarea>
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        );
      } else {
        return <h3>{"No Transactions Found"}</h3>;
      }
    } else if (feedType === "employee") {
      return (
        <div>
          {(transactionTypes.length > 0
            ? transactionTypes
            : [{ unique_key: "1", transaction_type: "" }]
          ).map((transaction, index) => (
            <div key={transaction.unique_key} className="transaction-card">
              <h3>
                {transaction.transaction_type
                  ? index + 1 + ". " + transaction.transaction_type
                  : ""}
              </h3>

              <ul className="list-inline">
                {ratingOptions.map((option) => (
                  <li
                    key={option.key}
                    className={`list-inline-item ${fed[transaction.unique_key]?.rating === option.key
                        ? "highlight"
                        : ""
                      }`}
                    onClick={() =>
                      handleRatingChange(
                        option.key,
                        option.ratingKey,
                        transaction
                      )
                    }
                  >
                    <figure
                      className={`${option.className} ${fed[transaction.unique_key]?.rating === option.key
                          ? "highlight"
                          : ""
                        }`}
                    >
                      {option.label}
                    </figure>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {isFeedbackRequired && (
            <div className="col-lg-12 badQue">
              <h4>{lang.poorFeedbackHeader}</h4>
              <form>

                {feedbackReasons.map((reasonItem) => (
                  <div className="form-group" key={reasonItem.id}>

                    <input
                      type="checkbox"
                      id={reasonItem.id}
                      onChange={(e) => handleReasonChange(e)}
                      checked={reason.includes(reasonItem.id)}
                    />
                    <label htmlFor={reasonItem.id}>{reasonItem.value}</label>
                  </div>
                ))}


                {idsToCheck.some((id) => reason.includes(id)) && (
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      value={comments}
                      rows={3}
                      cols={50}
                      onChange={(e) => setComments(e.target.value)}
                      maxLength="500"
                      placeholder="Additional comments..."
                    ></textarea>
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
      );
    }
  }
  // function renderFeedback()
  // {

  //   return(
  //     <div>
  //     {/* Transaction List */}
  //     {(transactionTypes.length > 0 ? transactionTypes : [{unique_key:'1',transaction_type:''}]).map((transaction,index) => (
  //       <div key={transaction.unique_key} className="transaction-card">
  //         <h3>{index + 1 + ". " + transaction.transaction_type}</h3>

  //         {/* Rating Selection */}
  //         <ul className="list-inline">
  //           {ratingOptions.map((option) => (
  //             <li
  //               key={option.key}
  //               className={`list-inline-item ${fed[transaction.unique_key]?.rating === option.key ? "highlight" : ""}`}
  //               onClick={() => handleRatingChange(option.key,option.ratingKey, transaction)}
  //             >
  //               <figure className={`${option.className} ${fed[transaction.unique_key]?.rating === option.key ? "highlight" : ""}`}>
  //                 {option.label}
  //               </figure>
  //             </li>
  //           ))}
  //         </ul>
  //       </div>
  //     ))}

  //     {/* Feedback Section (Common for all transactions) */}
  //     {isFeedbackRequired && (
  //       <div className="col-lg-12 badQue">
  //         <h4>{lang.poorFeedbackHeader}</h4>
  //         <form>
  //           {/* Checkbox options (Unique for each transaction) */}
  //           {feedbackReasons.map((reasonItem) => (
  //             <div className="form-group" key={reasonItem.id}>
  //               {/* Pass uniqueKey to handleReasonChange */}
  //               <input
  //                 type="checkbox"
  //                 id={reasonItem.id}
  //                 onChange={(e) => handleReasonChange(e)} 
  //                 checked={reason.includes(reasonItem.id)}
  //               />
  //               <label htmlFor={reasonItem.id}>{reasonItem.value}</label>
  //             </div>
  //           ))}

  //           {/* Comment box (Only if "Others" is selected) */}
  //           {idsToCheck.some(id => reason.includes(id)) && (
  //             <div className="form-group">
  //               <textarea
  //                 className="form-control"
  //                 value={comments}
  //                 rows={3}
  //                 cols={50}
  //                 onChange={(e) => setComments(e.target.value)}
  //                 maxLength="500"
  //                 placeholder="Additional comments..."
  //               ></textarea>
  //             </div>
  //           )}
  //         </form>
  //       </div>
  //     )}

  //   </div>
  //   )
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!rating) {
  //     setError('Please select a rating.');
  //     return;
  //   }

  //   if (!validateCaptcha(captchaInput)) {
  //     setError(lang.error.incorrectCaptcha);
  //     loadCaptchaEnginge(4);
  //     setCaptcha('');
  //     return;
  //   }

  //   // Retrieve uniqueKey from local storage or URL
  //   let uniqueKey = localStorage.getItem('uniqueKey');
  //   const searchParams = new URLSearchParams(location.search);
  //   if (!uniqueKey) {
  //     uniqueKey = searchParams.get('id');
  //   }

  //   if (!uniqueKey) {
  //     toast.error(lang.error.submitFeedback);
  //     return;
  //   }

  //   const isUniqueIDValid = await validateUniqueID(uniqueKey);
  //   if (!isUniqueIDValid) {
  //     return;
  //   }

  //   const customer_id = localStorage.getItem('customer_id') || "user1";
  //   const dp_code = localStorage.getItem('dp_code') || "dp001";
  //   const transactionType = localStorage.getItem('transactionType') || "";
  //   const feedbackType = location.state?.feedbackType || "general";
  //   const phone_number = localStorage.getItem('phone_number');
  //   const requestBody = {
  //     Request: {
  //       body: {
  //         encryptData: {
  //           questions_id: "1",
  //           user_id: customer_id || APIresponse?.data?.customer_id || "customer1",
  //           unique_id: uniqueKey,
  //           answer: rating,
  //           language: choosenLang,
  //           user_type: !searchParams.get('id') && !feedbackType ? "employee" : "customer",
  //           feedback_category: feedbackType ? "kiosk" : searchParams.get('id') ? "transaction" : "QR",
  //           feedback_type: feedbackType || "general",
  //           dp_code: dp_code || APIresponse?.data?.dp_code || "dp001",
  //           transaction_type: transactionType || "general",
  //           mobile_number: phone_number,
  //           notification_opted: false,
  //           free_text: (rating === 'poor' || rating === 'very-poor') ? comments : '', // Include free_text if rating is poor or very poor
  //         }
  //       }
  //     }
  //   };
  //   console.log(requestBody)
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/saveFeedbackResponse`, requestBody);
  //     navigate('/thank-you');
  //   } catch (error) {
  //     console.error('API call failed:', error);
  //     setError('Something went wrong. Please try again.');
  //   }
  // };
  function renderTransactionContainer() {
    
    if (feedType === "service" && localStorage.getItem("kiosk_or_qr")) {
      if (transactionTypes && transactionTypes.length > 0) {
        return (
          <>
            <div className="col-lg-12 ratingCol">
              <h3>{questionData}</h3>
              <div className="col-sm-12 rate">
                <span>{lang.selectRating}</span>

                {renderFeedback()}
              </div>

              <div className="col-lg-12 captcha">
                <LoadCanvasTemplate reloadText={lang.reload} />
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder={lang.captchaPlaceholder}
                  value={captchaInput}
                  onChange={(e) => setCaptchaInput(e.target.value)}
                />
              </div>
            </div>
            <>{error && <p className="text-danger text-center">{error}</p>}</>
            <div className="col-sm-12">
              <button
                onClick={handleSubmit}
                className="button submitBtn btn btn-primary mt-3"
                disabled={
                  !rating ||
                  !captchaInput ||
                  !isValid ||
                  (idsToCheck.some((id) => reason.includes(id)) &&
                    comments === "") ||
                  (isFeedbackRequired && reason.length === 0) || isButtonPressed
                }
              >
                {lang.submit}
              </button>
            </div>
          </>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 400,
              width: 600,
            }}
          >
            <h4
              style={{ textAlign: "center", alignSelf: "center", color: "red" }}
            >
              NO Transactions Found For This Mobile Number
            </h4>
          </div>
        );
      }
    } else if (feedType === "employee") {
      return (
        <>
          <div className="col-lg-12 ratingCol">
            <h3>{questionData}</h3>
            <div className="col-sm-12 rate">
              <span>{lang.selectRating}</span>

              {renderFeedback()}
            </div>

            <div className="col-lg-12 captcha">
              <LoadCanvasTemplate reloadText={lang.reload} />
              <input
                type="text"
                className="form-control mt-2"
                placeholder={lang.captchaPlaceholder}
                value={captchaInput}
                onChange={(e) => setCaptchaInput(e.target.value)}
              />
            </div>
          </div>
          <>{error && <p className="text-danger text-center">{error}</p>}</>
          <div className="col-sm-12">
            <button
              onClick={handleSubmit}
              className="button submitBtn btn btn-primary mt-3"
              disabled={
                !rating ||
                !captchaInput ||
                !isValid ||
                (idsToCheck.some((id) => reason.includes(id)) &&
                  comments === "") ||
                (isFeedbackRequired && reason.length === 0)
              }
            >
              {lang.submit}
            </button>
          </div>
        </>
      );
    }
  }
  return (
    <>
      {
        <div>
          <section className="headerBox blue-bg">
            <div className="container-fluid">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 pt-2 pb-2 top">
                    <a href="#">
                      <img src="images/logo.png" alt="Canara Bank" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid langCol">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 bottom pt-1 pb-1 top">
                    <SelectLang />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {
            <section className="formBox">
              <div className="container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 inner pb-4">
                      <div className="col-sm-7">
                        {renderTransactionContainer()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }

          <footer className="footerBox mt-3">
            <div className="container-fluid">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <p>@2024, Canara Bank, All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      }
    </>
  );
};

export default Feedback;
