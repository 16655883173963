import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; // Assuming you have a custom CSS file
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { useNavigate, useLocation } from 'react-router-dom';

const ThankYou = () => {
  const langs = useLanguage();
  const lang = langs.thankYou;
  const lang2 = langs.feedback;
  const navigate = useNavigate();
  const location = useLocation();
  const feedbackType = location.state?.fbType;
  const isKiosk = (localStorage.getItem('kiosk_or_qr') && (localStorage.getItem('kiosk_or_qr') == 'kiosk'))?true:false;
  console.log("location.state",location.state);

  // useEffect(() => {
  //   const handlePopState = () => {
  //     if(feedbackType === 'kiosk'){
  //       navigate('/'); 
  //     }
  //     else{
  //       // navigate(`/phone-number${location.search}`)
  //       navigate(`/phone-number`)
  //     }
  //   };

  //   // Push the current path to the history stack
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener('popstate', handlePopState);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [navigate]);
  

  useEffect(() => {
    // console.log("feedbackType in Thankyou.js",feedbackType);
    // if (feedbackType === 'kiosk') {
    if(isKiosk){
      setTimeout(() => {
        navigate(`/cankiosk`)
      }, 5000);
    }else{
      setTimeout(() => {
        // navigate(`/phone-number`)
        window.open("about:blank", "_self")
        window.close();
      }, 5000);
    }
  }, [feedbackType, isKiosk, navigate]);
  

  return (
    <div>
      {/* Header Section */}
      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <a href="#">
                  <img src="images/logo.png" alt="Canara Bank Logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="container-fluid langCol">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 bottom pt-1 pb-1 top">
                  <SelectLang />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Thank You Section */}
      <section className="thankBox">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 inner text-center">
                <img src="images/thank-you.png" alt="Thank You" className="img-fluid" />
                
                
                {(feedbackType == 'err') ? (
                    <p>
                      {lang2.error.submitFeedback}
                    </p>
                  ) : (<p>
                    {lang.line1} <br />
                    {lang.line2}
                    </p>)}
                
                  
                
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
                <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ThankYou;
