import React from "react";
import { Helmet } from "react-helmet-async";

const Layout = ({ children }) => {
  const API_URL = process.env.REACT_APP_API_BASE_URL || "";

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`default-src 'self'; connect-src 'self' ${API_URL}; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;`}
        />
        <meta httpEquiv="Cross-Origin-Embedder-Policy" content="require-corp" />
        <meta httpEquiv="access-control-allow-headers" content='Content-Type,Authorization' />
        <meta httpEquiv="access-control-allow-origin" content={API_URL} />
        <meta httpEquiv="access-control-allow-methods" content='GET' />
        <meta httpEquiv="Cross-Origin-Opener-Policy" content="same-origin" />
        <meta httpEquiv="Cross-Origin-Resource-Policy" content="cross-origin" />
        <meta httpEquiv="X-Frame-Options" content="DENY" />
        <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
        <meta httpEquiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains; preload" />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <meta httpEquiv="Permissions-Policy" content="geolocation=(), microphone=(), camera=(), payment=()" />
      </Helmet>

      {children}
    </>
  );
};

export default Layout;
