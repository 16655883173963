import React, { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { v4 as uuidv4 } from 'uuid';
// require('dotenv').config();
import $ from "jquery";
import { useNavigate, useLocation } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { useSelector } from 'react-redux';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useEnglish from '../languages/useEnglish';
import { encryptData,decryptData } from '../utils/cryptoUtils';
import "./QR.css";

const Feedback = () => {
  const [isValid, setIsValid] = useState(true);
  const langs = useLanguage();
  const eng = useEnglish();
  const engFeedback = eng.feedback;
  const lang = langs.feedback;
  const choosenLang = useSelector((state)=>state.language.language);

  const idsToCheck = [6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120, 126, 132, 138, 144, 150, 156, 162, 168, 174, 180, 185, 190, 195, 200,205, 210, 215, 220, 225, 230, 235, 240, 245, 250,255, 260, 265, 270, 275,280, 285, 291, 296, 301, 306, 311, 316,321, 326,331];
  const [rating, setRating] = useState('');
  const [answers, setAnswers] = useState({}); 
  const [ratings, setRatings] = useState({}); 
  const [feedback, setFeedback] = useState({});
  const [comment, setComment] = useState({});
  const [reason, setReason] = useState([]);
  const [comments, setComments] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [error, setError] = useState('');
  const [questionData, setQuestionData] = useState('');
  const [questionId, setQuestionId] = useState(1);
  const [feedbackReasons, setFeedbackReasons] = useState([]);
  const [ratingKey, setRatingKey] = useState("");
  const [ratingNum, setRatingNum] = useState([]);
  const [feedbackCateg, setFeedbackCateg] = useState("");
  const [currentQue, setCurrentQue] = useState("");
  // const [fbType, setFbtype] = useState('');
  const navigate = useNavigate();
const location = useLocation();
const branch_code = localStorage.getItem('branch_code') || '';
let feedbackType = location.state?.feedbackType;
// if(branch_code) feedbackType = 'branch';

  useEffect(() => {
    loadCaptchaEnginge(4); 
  }, []);

 

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uniqueKey = searchParams.get('id');
    console.log('feedbackType',feedbackType)
    if (uniqueKey) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkTimestamp`, { unique_key : uniqueKey })
        .then(response => {
          console.log(response)
          if (response.data.valid === true) {
            setIsValid(true);
          } else {
            setIsValid(false);
            setError("Invalid Link")
            toast.error('The link is expired or invalid.');
          }
        })
        .catch(error => {
          console.error('API Error:', error);
          setIsValid(false);
          setError('An error occurred while validating the link.');
        });
    }else{
      
    } 
  }, []);

  useEffect(() => {
    const fetchResponses = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/getReasons`, {
          language: choosenLang,
          service: searchParams.get('id') ? 'service' : feedbackType || 'general'
         
        });
        console.log('Responses:', response.data);
        setFeedbackReasons(response.data?.responses || [])
      } catch (error) {
        setFeedbackReasons([])
        console.error('Error fetching responses:', error);
        setError('Failed to load responses. Please try again.');
      }
    };
  
    fetchResponses();
  }, [choosenLang, feedbackType, rating]);
  

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
      
        let unique_key = localStorage.getItem('uniqueKey');
      
          const isUniqueID_Valid =  validateUniqueID(unique_key);
          if (!isUniqueID_Valid) {
            console.log('Invalid unique_key');
          }else{
            console.log('Valid unique_key');
          }

        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/getQuestions`, {
          type: feedbackType || 'general',
          language: choosenLang,
        });
        console.log(response.data.questions)
        const questionIds = response.data.questions.map(que => que.id);
        console.log('questionIds',questionIds)
        setQuestionId(questionIds)

        const questions = response.data.questions;
        // const data = Array.from(questions);
        setQuestionData(questions);

        
        // setQuestionId(response.data.questions[0].id)
        // setQuestionData(response.data.questions[0].value);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError('Failed to load questions. Please try again.');
      }
    };

    fetchQuestions();
  }, [choosenLang, feedbackType])

  useEffect(()=>{
   console.log(reason);
  },[reason])

  const handleRatingChange = (e, selectedRating,ratingKey, queId, ratingNum) => {
    setRating(selectedRating);
    
    setRatingKey(ratingKey);
    setCurrentQue(queId);
    console.log(rating)
    setReason([]); 
    setAnswers((prev) => ({ ...prev, [queId]: selectedRating }));
    setRatings((prev) => ({ ...prev, [queId]: engFeedback[ratingKey] }));
    setRatingNum((prev) => ({ ...prev, [queId]: ratingNum }));
  setComments(''); 
  setError('');
// console.log('e.currentTarget',$(e.currentTarget).hasClass('showBad'))
    var currentTarget= $(e.currentTarget);
    $('.row'+queId).removeClass('highlight');
    $(currentTarget).addClass('highlight');

    // if((rating === lang.average) || (rating === lang.belowAverage)){
    if($(e.currentTarget).hasClass('showBad')){
        $('#reason'+queId).removeClass('hide');
        $('#reason'+queId).addClass('show');
    }else{
        $('#reason'+queId).addClass('hide');
        $('#reason'+queId).removeClass('show');
    }
  };

  const handleReasonChange = (questionId, reasonId) => {
    // const { id, checked } = e.target;
    // const idNumber = Number(id); // Ensure the id is handled as a number
    // setReason((prevReasons) => {
    //   const newReasons = checked
    //     ? [...new Set([...prevReasons, idNumber])] // Use idNumber instead of id
    //     : prevReasons.filter((reason) => reason !== idNumber);
  
    //   console.log("Updated reasons:", newReasons);
    //   return newReasons;
    // });
    setFeedback((prev) => {
      const updatedReasons = prev[questionId] || [];
      if (updatedReasons.includes(reasonId)) {
          return { ...prev, [questionId]: updatedReasons.filter(id => id !== reasonId) };
      } else {
          return { ...prev, [questionId]: [...updatedReasons, reasonId] };
      }
  });
  };
  
    const handleCommentChange = (questionId, newComment) => {
      setComment((prev) => ({
        ...prev,
        [questionId]: newComment
    }));
    };
  
  const validateUniqueID = async (uniqueKey) => {
    const searchParams = new URLSearchParams(window.location.search);
    const encryptedEmpCode = localStorage.getItem('emp_code') || localStorage.getItem("employeeCode");
    console.log("encryptedEmpCode",encryptedEmpCode);
    const empCode = decryptData(encryptedEmpCode);
    console.log("decryptedEmpCode",empCode);
    try {
      let feedbackTypeLocal = location.state?.feedbackType || "general";
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-uniquekey`, {
        uniqueKey: uniqueKey,
        feedbackType : searchParams.get('id') ? 'service' : feedbackTypeLocal,
        empCode : empCode,
        dpCode : branch_code,
        service:'feedback'
      });
      console.log("response from validation",response)
      const dpCode = response?.data?.dp_code || localStorage.getItem('dp_code') || localStorage.getItem("employeeDpCode");
      const custId = response?.data?.customer_id;
      const channel = response?.data?.channel;
      console.log("dpCode",dpCode);
      console.log("custId",custId);
      localStorage.setItem('customer_id', custId);
      localStorage.setItem('channel', channel);
      if(response?.data?.dp_code){
        const encryptedDpCode = encryptData(dpCode);
        localStorage.setItem('dp_code', encryptedDpCode);
      }

      if (response.data.status === 'valid') {
        return true;
      } else if(response.data.status === 'invalid'){

        if(feedbackTypeLocal == 'employee'){
          localStorage.clear()
        }else{
          // localStorage.removeItem('branch_name');
          localStorage.removeItem('uniqueKey');
          localStorage.removeItem('phone_number');
          localStorage.removeItem('customer_id');
        }

        if(response.data.lastUpdated==2){
          toast.error(response.data.message)
          navigate(`/feedback-response`,{ state: { fbType: 'err' } })
        }else if(response.data.lastUpdated <1){
          // alert(lang.error.twice);
          // navigate(`/feedback-response${location.search}`,{ state: { fbType: feedbackCateg } })
          navigate(`/feedback-response`,{ state: { fbType: feedbackCateg } })
        }
        else{
          toast.error(lang.error.transaction)
        }
        return false;
      }
      else{
        if(feedbackTypeLocal == 'employee'){
          localStorage.clear()
        }else{
          // localStorage.removeItem('branch_name');
          localStorage.removeItem('uniqueKey');
          localStorage.removeItem('phone_number');
          localStorage.removeItem('customer_id');
        }

        navigate(`/feedback-response`,{ state: { fbType: feedbackCateg } })
        return false;
      }
    } catch (error) {
      console.error('Error validating unique ID:', error);
      toast.error(lang.error.submitFeedback);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!rating) {
      setError('Please select a rating.');
      return;
    }
  
    // Validate reasons if rating is 'poor' or 'very poor'
    // if ((rating === lang.average || rating === lang.belowAverage) && reason.length === 0) {
    //   setError('Please select at least one reason for your rating.');
    //   return;
    // }
    const q_answer = Object.values(answers);
    if (q_answer.some(value => value === lang.average || value === lang.belowAverage) && feedback.length === 0) {
      setError('Please select at least one reason for your rating.');
      return;
    }
  
    // Validate comments if 'others' is selected
    if (reason.includes('others') && comments.trim() === '') {
      setError('Please provide details for the "Others" option.');
      return;
    }
  
    if (!validateCaptcha(captchaInput)) {
      setError(lang.error.incorrectCaptcha);
      loadCaptchaEnginge(4);
      setCaptcha('');
      return;
    }
    
    const searchParams = new URLSearchParams(location.search);
    let uniqueKey = searchParams.get('id');
    if (!uniqueKey) {
      uniqueKey = localStorage.getItem('uniqueKey');
    }
  
    if (!uniqueKey) {
      toast.error(lang.error.submitFeedback);
      return;
    }
  
    const isUniqueIDValid = await validateUniqueID(uniqueKey);
    if (!isUniqueIDValid) {
      return;
    }
    const encryptedDpCode = localStorage.getItem('dp_code') || localStorage.getItem('employeeDpCode');
    console.log("encryptedDpCode",encryptedDpCode);
    const decryptedDpCode = decryptData(encryptedDpCode);
    console.log("decryptedDpCode",decryptedDpCode);

    const encryptedEmpCode = localStorage.getItem('emp_code') || localStorage.getItem("employeeCode");
    console.log("encryptedEmpCode",encryptedEmpCode);
    const decryptedEmpCode= decryptData(encryptedEmpCode);
    console.log("decryptedEmpCode",decryptedEmpCode);
    const customer_id = localStorage.getItem('customer_id') || "user1";
    
    const transactionType = localStorage.getItem('transactionType') || "";
    let feedback_type = location.state?.feedbackType || "general";
    const phone_number = localStorage.getItem('phone_number');


    if(decryptedEmpCode){
      feedback_type = 'employee';
    }

    let feedback_category;
    if (feedback_type === 'employee') {
      feedback_category = 'QR';
    } else if ( (feedback_type === 'branch') || localStorage.getItem('branch_code') ){
        feedback_category = 'QR';
    } else if (feedback_type === 'service') {
      feedback_category = 'transaction';
    } else if (searchParams.get('id')) {
      feedback_category = 'transaction';
    } else {
      feedback_category = 'kiosk';
    }
    setFeedbackCateg(feedback_category);
    // setFbtype(feedback_category);
    const isKiosk = (localStorage.getItem('kiosk_or_qr') && (localStorage.getItem('kiosk_or_qr') == 'kiosk'))?true:false;
    const channel = isKiosk?'kiosk':localStorage.getItem('channel');

    const dpCode =  decryptedDpCode || localStorage.getItem('branch_code')

    if(!dpCode){        
      if(isKiosk){
        navigate(`/cankiosk`);
      }else{
        navigate(`/phone-number`);
      }
      
    }
    const newFeedbackType = searchParams.get('id') ? 'service' : feedback_type;
    const requestBody = {
      Request: {
        body: {
          encryptData: {
            questions_id: questionId,
            user_id: customer_id || "customer1",
            unique_id: uniqueKey,
            // answer: rating,
            // answer: Object.values(answers),
            // answer_english : Object.values(ratings),
            answer: answers,
            answer_english : ratings,
            rating_key: ratingNum,
            language: choosenLang,
            user_type: feedback_type == 'employee' ? "employee" : "customer",
            feedback_category: newFeedbackType === 'service' ? "transaction" : feedback_category,
            feedback_type: newFeedbackType,
            dp_code: dpCode,
            transaction_type: transactionType || "general",
            mobile_number: phone_number,
            notification_opted: "false",
            // free_text:Object.values(comment),
            free_text:comment,
           // free_text: (rating === lang.average || rating === lang.belowAverage) ? comments : '',
            // other_opts : reason,
            // other_opts : Object.values(feedback).flat(),
            other_opts : feedback,
            emp_id : decryptedEmpCode,
            channel:channel??null
          }
        }
      }
    };
    console.log('requestBody', requestBody);
    // return;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/saveGeneralFeedbackResponse`, requestBody);
      if(feedback_type == 'employee'){
        localStorage.clear()
      } else{
        // localStorage.removeItem('branch_name');
        localStorage.removeItem('uniqueKey');
        localStorage.removeItem('phone_number');
        localStorage.removeItem('customer_id');
      }
      // navigate(`/thank-you${location.search}`, { state: { fbType: feedback_category } });
      navigate(`/thank-you`, { state: { fbType: feedback_category } });
    } catch (error) {
      console.error('API call failed:', error);
      setError('Something went wrong. Please try again.');
    }
  };

  useEffect(()=>{
    const dpCodeVal = localStorage.getItem("employeeCode") || localStorage.getItem('dp_code') || localStorage.getItem('branch_code');
    if(dpCodeVal == undefined || dpCodeVal == ""){
      setIsValid(false);
      setError("The link is expired or invalid.")
      // toast.error('The link is expired or invalid.');
    }
  },[])

  const OTHER_REASON_ID = feedbackReasons.find(reason => 
    reason.value.toLowerCase() === "others"
)?.id;
  
  function renderQuestions()
{
   console.log('questions',feedback);
    if(questionData && questionData.length>0){
       const queData = questionData.map((item,index)=>{
            return (<>
            <h3>{index + 1 + '. ' + item.value}</h3>
            <div className="col-sm-12 rate">
            <span>{lang.selectRating}</span>
            <ul className="list-inline">
            <li className={`list-inline-item showBad row${item.id}`} id={`que1${item.id}`} onClick={(e) => handleRatingChange(e,lang.belowAverage,'belowAverage', item.id, 1)}>
                <figure className={ `fig1`} id={`fig1${item.id}`}>1
                    {/* <img src="images/very-good.png" alt={lang.excellent} height={"40px"} width={"40px"} /> */}
                </figure>
                {/* <b></b> */}
                </li>
                <li className={`list-inline-item showBad row${item.id}`} id={`que2${item.id}`} onClick={(e) => handleRatingChange(e,lang.average,'average', item.id, 2)}>
                <figure className={`fig2`} id={`fig2${item.id}`}>2
                    {/* <img src="images/good.png" alt={lang.veryGood} height={"40px"} width={"40px"} /> */}
                </figure>
                {/* <b></b> */}
                </li>
                <li className={`list-inline-item row${item.id}`} id={`que3${item.id}`} onClick={(e) => handleRatingChange(e,lang.good,'good', item.id,3)}>
                <figure className={`fig3`} id={`fig3${item.id}`}>3
                    {/* <img src="images/average.png" alt={lang.good} height={"40px"} width={"40px"} /> */}
                </figure>
                {/* <b></b> */}
                </li>
                <li className={`list-inline-item row${item.id}`} id={`que4${item.id}`} onClick={(e) => handleRatingChange(e,lang.veryGood,'veryGood', item.id, 4)}>
                <figure className={`fig4`} id={`fig4${item.id}`}>4
                    {/* <img src="images/poor.png" alt={lang.average} height={"40px"} width={"40px"}  /> */}
                </figure>
                {/* <b></b> */}
                </li>
                <li className={`list-inline-item row${item.id}`} id={`que5${item.id}`} onClick={(e) => handleRatingChange(e,lang.excellent,'excellent', item.id, 5)}>
                <figure className={`fig5`}  id={`fig5${item.id}`}>5
                    {/* <img src="images/very-poor.png" alt={lang.belowAverage} height={"40px"} width={"40px"}/> */}
                </figure>
                {/* <b></b> */}
                </li>
                {/* <li className={`list-inline-item ${rating === lang.verypoor ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.verypoor,'verypoor', item.id)}>
                <figure>
                    <img src="images/very-poor.png" alt={lang.verypoor} height={"40px"} width={"40px"}/>
                </figure>
                <b>{lang.verypoor}</b>
                </li> */}
            </ul>
            </div>

              
                <div className="col-lg-12 badQue hide" id={`reason${item.id}`}>
                <h4>{lang.poorFeedbackHeader}</h4>
                <form>
                    {feedbackReasons.map((reasonItem) => {
                       
                         if(item.id == reasonItem.question_id){
                            return (
                                <div className="form-group" key={reasonItem.id}>
                                <input
                                    type="checkbox"
                                    id={reasonItem.id}
                                    onChange={() => handleReasonChange(item.id, reasonItem.id)}
                                    checked={feedback[item.id]?.includes(reasonItem.id) || false}
                                    // onChange={handleReasonChange}
                                    // checked={reason.includes(reasonItem.id)}
                                />
                                <label htmlFor={reasonItem.id}>
                                    {reasonItem.value}
                                </label>
                                </div>
                            );
                         }
                    })}
                    {idsToCheck.some(id => feedback[item.id]?.includes(id)) && (
                        <div className="form-group">
                            <textarea
                            className="form-control"
                            value={comment[item.id] || ""}
                            rows={10}
                            cols={100}
                            onChange={(e) => handleCommentChange(item.id, e.target.value)}
                            maxLength="500"
                            ></textarea>
                        </div>
                    )}

                </form>
                </div>
            
        </>)
    })

    return queData;
    }
}  

  return  (
    <>
    {
    <div>
      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <a href="#">
                  <img src="images/logo.png" alt="Canara Bank"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
      </section>

      {<section className="formBox">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 inner pb-4">
                <div className="col-sm-7">
                  <div className="col-lg-12 ratingCol">
                 
                 {/* render questions */}
                    {renderQuestions()}
  
                    <div className="col-lg-12 captcha">
                      <LoadCanvasTemplate reloadText={lang.reload}/>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder={lang.captchaPlaceholder}
                        value={captchaInput}
                        onChange={(e) => setCaptchaInput(e.target.value)}
                      />
                    </div>
                  </div>
                  {error && <p className="text-danger text-center">{error}</p>}
                  <div className="col-sm-12">
                  <button
  onClick={handleSubmit}
  className="button submitBtn btn btn-primary mt-3"
  disabled={
    !rating || 
    !captchaInput || 
    !isValid || 
    (idsToCheck.some(id => reason.includes(id)) && comments === '') ||
    ((rating === lang.average || rating === lang.belowAverage) && feedback.length === 0)
  }
>
                      {lang.submit}
                    </button>
                  </div> 
                  
                </div>
                
              </div>
             
            </div>
          </div>
        </div>
      </section>}

      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
              <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div> }
    </>
  );
};

export default Feedback;
