import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import axios from 'axios';
import { redirect, useNavigate } from 'react-router-dom';
import { encryptData,decryptData } from '../utils/cryptoUtils';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';

function EmployeeCode() {
    const langs = useLanguage();
    const lang = langs.employee;
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const [dpCode, setDpCode] = useState("");
    const [employeeCode, setEmployeeCode] = useState('');
    const [qrType, setQrType] = useState('employee');
    const [searchTerm, setSearchTerm] = useState(''); // For searching branch codes
    const [branchCodes, setBranchCodes] = useState([]); // Store branch codes from API
    const [filteredBranchCodes, setFilteredBranchCodes] = useState([]); 
    const [branchName, setBranchName] = useState(localStorage.getItem('branch_name') || "");
    const [branchFetched, setBranchFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {
        if (!code) {
            setLoading(true); // Show loader during redirection
            setTimeout(() => {
                navigate('/employee-feedback');
            }, 3000); // Set timeout for 3 seconds to show loader before redirect
        }

        if(!branchFetched) fetchBranchCodes();

        setBranchFetched(true);
    }, [code, navigate]);

    // Filter branch codes based on search term
    useEffect(() => {
    if (searchTerm) {
        const filtered = branchCodes.filter(branch => 
        (branch.name && branch.name.toLowerCase().includes(searchTerm.toLowerCase())) || 
        (branch.dpcd__c && String(branch.dpcd__c).includes(searchTerm))
        );
        console.log(filtered)
        setFilteredBranchCodes(filtered);
    } 
    }, [searchTerm, branchCodes]);
    
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value); // Update search term on input change
    };

    const handleSelectBranchCode = (branch) => {
        setSearchTerm(`${branch.name} (${branch.dpcd__c})`);
        const dpCode = branch.dpcd__c;
        console.log("branch",branch)
        const encryptedDpCode = encryptData(dpCode);
        localStorage.setItem('branch_code', encryptedDpCode);
        localStorage.setItem('branch_name', branch.name);
        setDpCode(dpCode);
        setBranchName(branch.name)
    };
    
    // Fetch branch codes from API when the component mounts
    const fetchBranchCodes = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/branch-codes?qr=1`); // Assuming the API endpoint is /branch-codes
        setBranchCodes(response.data);
      } catch (error) {
        console.error('Error fetching branch codes:', error);
      }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('dpCode',dpCode)
        if (! (employeeCode.trim() || dpCode) ) {
            toast.warn(lang.error.error6);
            return;
        }
        if (code) {
            localStorage.setItem('salesforceCode', code);

            if(dpCode){
                navigate('/qr',{ state: { feedbackType : 'branch'} });
            }else{
                const apiUrl = API_ENDPOINT + '/api/getEmployeeDetails';

                const requestBody = {
                    "stno": employeeCode
                };
                axios.post(apiUrl, requestBody)
                    .then(response => {
                        console.log("response.",response)
                        let result;
                        if (response?.data?.getDetailsbystnoResponse?.getDetailsbystnoResult) {
                            result = response.data.getDetailsbystnoResponse.getDetailsbystnoResult;
                        } else if (response?.data?.getStaffDetailsResponse?.getStaffDetailsResult) {
                            result = response.data.getStaffDetailsResponse.getStaffDetailsResult;
                        }
                        console.log("result",result);
                        if (!result || result.includes('System.NullReferenceException')) {
                            toast.error(lang.error.error5);
                            console.error("Employee not found. Response:", result);
                            return;
                        }
                        const details = result.split('|');
                    
                        let dpcode = details[3]
                        if(isNaN(dpcode)){                      
                            dpcode = details[2]
                        }
                        const extractedEmployeeCode = details[0]; // 700922
                        let extractedEmployeeName = details[9]; // MAMIDALA NITIN VYAS
                        let extractedDpCode = dpcode;
                        let extractedBranchName = details[8];
                        const empGrade = parseInt(details[17]);
                        if(empGrade < 300)
                        {
                            console.log(empGrade,'empGrade');
                            toast.error('You are not eligible for QR');
                            return false;
                        }
                        extractedEmployeeName = extractedEmployeeName.replace(',', '');
                        // Save to local storage
                        localStorage.setItem('employeeCode', extractedEmployeeCode);
                        localStorage.setItem('employeeName', extractedEmployeeName);
                        localStorage.setItem('employeeDpCode', extractedDpCode);
                        localStorage.setItem('employeeBranchName',extractedBranchName);
                        navigate('/qr',{ state: { feedbackType : 'employee'} });

                        console.log('Employee Code:', extractedEmployeeCode);
                        console.log('Employee Name:', extractedEmployeeName);
                        console.log('Employee DP Code',extractedDpCode);
                        console.log('Employee Branch Name',extractedBranchName);
                        
                    })
                    .catch(error => {
                        console.error('Error fetching employee details:', error);
                        toast.error(lang.error.error2);
                    });
            }

        } else {
            toast.error(lang.error.error3);
        }



    };

    const handleTypeChange = (type) => {
        // event.preventDefault();
        setQrType(type);

        if(type == 'branch'){
            fetchBranchCodes();

            console.log("branchCodes",branchCodes)
        }
        console.log('onchange', type)
    }
    return (
        <>
            {/* Header Box Start */}
            <section className="headerBox blue-bg">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 pt-2 pb-2 top">
                                <a href="#">
                                    <img src="images/logo.png" alt="Canara Bank Logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
            </section>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                    <p>{lang.error.error4}</p>
                </div>
            )}
            {/* Header Box End */}

            {/* Employee Code Section Start */}
            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 mobileBox">
                                <div className="col-sm-6 left">
                                    <img src="images/Employee-Code.png" alt="Employee Code" />
                                </div>

                                <div className="col-sm-6 right">
                                    <div className="inner">
                                        <h2>{lang.line5}</h2>
                                        <ul className='empOrBranch'>
                                            <li>
                                                <input type="radio" name="qrtype" value="employee" checked={(qrType == 'employee') ? 'checked' : ''} onClick={(e) => handleTypeChange(e.target.value)} /> Employee
                                            </li>
                                            <li>
                                                <input type="radio" name="qrtype" value="branch" checked={(qrType == 'branch') ? 'checked' : ''} onClick={(e) => handleTypeChange(e.target.value)} /> Branch
                                            </li>
                                        </ul>
                                        <br></br>
                                        <h2>{lang.line6}</h2>
                                        
                                        {/* <form onSubmit={handleSubmit}>
                                            <label htmlFor="mobile">{lang.line3}</label>
                                            <input type="text" className="mob" />
                                        </form>
                                        <a href="#" className="button">{lang.line4}</a> */}
                                        <form onSubmit={handleSubmit}>
                                            

                                        {(qrType == 'employee')?
                                            <input
                                                type="text"
                                                className="mob"
                                                id="employeeCode"
                                                placeholder ={lang.line7}
                                                value={employeeCode}
                                                onChange={(e) => setEmployeeCode(e.target.value)}
                                            /> : <div>
                                                    <input
                                                        type="text"
                                                        value={searchTerm}
                                                        onChange={handleInputChange}
                                                        placeholder="Search by Branch Name or Code"
                                                        />
                                                    <ul className="branch-list">
                                                        {filteredBranchCodes.map((branch, index) => (
                                                            <li key={index} onClick={() => handleSelectBranchCode(branch)}>
                                                            {branch.name} ({branch.dpcd__c})
                                                            </li>
                                                        ))}
                                                        </ul>
                                                </div>
                                            }
                                            <button type="submit" className="button">{lang.line4}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Employee Code Section End */}

            {/* Footer Box Start */}
            <footer className="footerBox mt-3">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 p-0">
                                <p>@2024, Canara Bank, All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Box End */}
        </>
    );
}

export default EmployeeCode;