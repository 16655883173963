import React, { useRef, useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
// import './style.css';
import QRCode from "react-qr-code";
import SelectLangQR from "./SelectLangQR";
import useLanguage from "../languages/useLanguage";
// import './QR.css';
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { encryptData, decryptData } from "../utils/cryptoUtils";
import "./Form.css";

const QR = () => {
  const location = useLocation();
  const langs = useLanguage();
  const lang = langs.qr;
  const langQuote = langs.qrQuote;

  // State for employee details from URL params
  const [empDetails, setEmpDetails] = useState({
    empCode: "",
    empName: "",
    empDpCode: "",
    empBranchName: "",
  });

  const [branchDetails, setBranchDetails] = useState({
    branchCode: "",
    branchName: ""
  });
  
  const [qrURL, setQRUrl] = useState("");

  const downloadRef = useRef(null);
  const feedbackType = location.state?.feedbackType;

  // Fetch query parameters and set them in state
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let queryString = ''

    console.log('feedbackType',feedbackType);
    if(feedbackType == 'branch'){
      const branchCode = decryptData(localStorage.getItem("branch_code"));
      const branchName = localStorage.getItem("branch_name");
      setBranchDetails({ branchCode, branchName });
      const type = 'branch'
      queryString = `${branchCode}|${type}`;
    }else{
      const empCode =
        params.get("emp_code") || localStorage.getItem("employeeCode") || localStorage.getItem("emp_code");
      const empName =
        params.get("emp_name") || localStorage.getItem("employeeName");
      const empDpCode =
        params.get("dp_code") || localStorage.getItem("employeeDpCode") || localStorage.getItem("dp_code");
      const empBranchName =
        params.get("branch_name") || localStorage.getItem("employeeBranchName");
      setEmpDetails({ empCode, empName, empDpCode, empBranchName });
      queryString = `${empCode}|${empDpCode}`;
    }
    console.log("queryString", queryString);
    const encryptedQuery = encryptData(queryString);
    console.log("encryptedQuery", encryptedQuery);
    const finalURL = `${
      process.env.REACT_APP_WEB_BASE_URL
    }phone-number?data=${encodeURIComponent(encryptedQuery)}`;
    console.log("finalURL", finalURL);
    setQRUrl(finalURL);
  }, []);

  const handleDownloadImage = () => {
    if (downloadRef.current === null) {
      return;
    }

    const empNameElement = document.querySelector(".emp-name");
    // if (empNameElement) empNameElement.style.display = 'block';

    const langComponent = document.querySelector(".langCol");
    const downloadButton = document.querySelector(".download-button");
    if (langComponent) langComponent.style.display = "none";
    if (downloadButton) downloadButton.style.display = "none";

    toPng(downloadRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const fileName = (feedbackType == 'branch') ? branchDetails.branchCode : (`${empDetails.empName || "QRCodePage"}.png`); // Use employee name in filename
        saveAs(dataUrl, fileName);
      })
      .catch((err) => {
        console.error("Failed to download image:", err);
      })
      .finally(() => {
        if (langComponent) langComponent.style.display = "";
        if (downloadButton) downloadButton.style.display = "";
      });
  };

  return (
    <div>
      <section className="qrPage">
        <div className="container-fluid">
          <section className="headerBox">
            <div className="centered-fluid">
              <div className="centered1">
                <div className="row">
                  <div className="col-lg-12 bottom pt-1 pb-1 top langCol">
                    <SelectLangQR />
                    <button
                      className="btn btn-link p-0 download-button"
                      onClick={handleDownloadImage}
                      style={{ border: "none", background: "transparent" }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2V16M12 16L8 12M12 16L16 12M4 18H20"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="centered1 " ref={downloadRef}>
            <div></div>
            <div className="row inner b-none">
              <div class="main_container1">
                <div class="container1">
                  <div class="header1">
                    <img src="formimages/canara_icon.png" class="logo_icon" />
                    <span>
                      <img src="formimages/canara_logo.png" class="logo" />
                      <h4>Together We can</h4>
                    </span>
                  </div>

                  <div class="content_style">
                    <div class="content_box">
                      <div class="scan">
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={`${qrURL}`}
                        />
                        <h4>Please Scan Here</h4>
                      </div>
                      <div>			
		<h2>आपके <strong>सुझाव </strong> हमारे लिए महत्वपूर्ण हैं। </h2>	
		<h2>Your <strong>Feedback</strong> Matters </h2>
    </div>		
                    </div>
                    <div class="content_box">
                      <span>                     
                        <h2 class="cust_txt">  
                        <strong><img className="quoteleft" src="formimages/comma_left.png" /> {lang}</strong>                   
                          {langQuote}
                          {/* प्रिय ग्राहक,
                          <br />
                          कृपया शाखा बैंकिंग अनुभव पर अपनी प्रतिक्रिया प्रस्तुत
                          करें और सर्वोत्तम अभ्यास प्रदान करने में हमारी सहायता
                          करें।{" "} */}
                        </h2>
                      </span>
                    </div>
                    <div class="content_box">
                      <img src="formimages/customer.png" class="cust_img" />
                      <h2 class="cust_txt">
                        <strong>Dear Customer,</strong>                       
                        Kindly submit your feedback on branch banking experience
                        and help us to provide the best service.
                        <img
                          className="quoteright"
                          src="formimages/comma_right.png"
                        />
                      </h2>
                    </div>
                    <div class="content_box">
                      <h4 class="cust_txt">
                        
                        {(feedbackType == 'branch')?
                          <strong>Name:- {branchDetails.branchName} ({branchDetails.branchCode})</strong>
                          :
                          <strong>Name:- {empDetails.empName}</strong>
                      }
                      </h4>
                    </div>
                  </div>

                  <div class="footer1">
                    {/* <ul>
                      <li>
                        <img src="formimages/whatsapp.png" />{" "}
                        <strong>9076030001</strong>
                      </li>
                    </ul>
                    <span>|</span> */}
                    <ul>
                      <li>
                        <img src="formimages/facebook.png" />
                      </li>
                      <li>
                        <img src="formimages/linkedin.png" />
                      </li>
                      <li>
                        <img src="formimages/pinterest.png" />
                      </li>
                      <li>
                        <img src="formimages/trend.png" />
                      </li>
                      <li>
                        <img src="formimages/youtube.png" />
                      </li>
                      <li>
                        <img src="formimages/X_icon.png" />
                      </li>
                      <li>
                        <img src="formimages/insta.png" />
                      </li>
                      <li>
                        <img src="formimages/user.png" />
                      </li>
                    </ul>
                    <span>|</span>
                    <ul>
                      <li>
                        <img src="formimages/1stbank.png" class="bank_styl" />{" "}
                        <strong>1800 1030 </strong>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <img src="formimages/lang.png" class="lang_styl" />
                      </li>
                    </ul>
                    <span>|</span>
                    <ul>
                      <li> www.canarabank.com</li>
                    </ul>
                  </div>
                </div>
                <div class="container1 backside">
                  <div class="header1">
                    <img src="formimages/canara_icon.png" class="logo_icon" />
                    <span>
                      <img src="formimages/canara_logo.png" class="logo" />
                      <h4>Together We can</h4>
                    </span>
                  </div>

                  <div class="content_style1">
                    <img src="formimages/banner.png" />
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-12">
                <a href="#" className="logo"><img src="images/logo.png" alt="Logo" width="200px" /></a>
              </div> */}

              {/* <div className="col-sm-12 pBox">
              <div className="emp-name" >
    <p style={{fontSize:'16px'}}>{empDetails.empName}</p>
    <p style={{fontSize:'14px'}} className="dpCode">{empDetails.empBranchName} ({empDetails.empDpCode})</p>
  </div>
                <p className="qouteIcon">
                  <p>{lang}</p>
                  Dear Customers, Your feedback matters! Kindly submit your feedback on branch banking experience to help us serve you better.
                </p>
              </div>

              <div className="col-sm-4 qrGirl">
                <img src="images/qr_girl.png" alt="QR Girl" />
              </div>
              <div className="col-sm-8 qrCode">
                <div className="qr-wrapper">
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`${qrURL}`}
                  />
                  <div className="blue-label">
                    <span>Your feedback matters</span><br />
                    <span>Scan Here</span>
                  </div>
                </div>
                <p>                 
                </p>
                <div style={{ height: "8px" }}></div>
              </div> */}
            </div>
          </div>
        </div>

        {/* <footer className="footerBox footerBox1 p-0" style={{ background: 'none' }}>
          <div className="centered-fluid">
            <div className="centered">
              <div className="row">
                <div className="col-lg-12 footercol">
                  <a href="#" className="call">
                    <img src="images/call.png" alt="Call" style={{ marginRight: '10px' }} /> 9076030001
                  </a>
                  <ul>
                    <li><a href="#"><img src="images/fb.svg" alt="Facebook" width="24px" /></a></li>
                    <li><a href="#"><img src="images/insta.svg" alt="Instagram" width="24px" /></a></li>
                    <li><a href="#"><img src="images/linkedin.svg" alt="LinkedIn" width="24px" /></a></li>
                    <li><a href="#"><img src="images/youtube.svg" alt="YouTube" width="24px" /></a></li>
                  </ul>
                  <img src="images/bankNo.png" alt="Bank No" width="150px" />
                </div>
              </div>
            </div>
          </div>
        </footer> */}
      </section>
    </div>
  );
};

export default QR;
